/********************************************
	BREAKPOINT WIDTHS
********************************************/
/********************************************
	FONTS
********************************************/
/********************************************
	COLOURS
********************************************/
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  transition: all 0.25s ease-in;
  opacity: 0.4;
  filter: alpha(opacity=40);
  position: absolute;
  z-index: 2;
  top: 20px;
  background: none;
  border: 0;
  font-size: 32px;
  cursor: pointer; }
  .carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100); }
  .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: ''; }
  .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none; }
  .carousel .control-prev.control-arrow {
    left: 0; }
    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid #fff; }
  .carousel .control-next.control-arrow {
    right: 0; }
    .carousel .control-next.control-arrow:before {
      border-left: 8px solid #fff; }

.carousel {
  position: relative;
  width: 100%; }
  .carousel * {
    box-sizing: border-box; }
  .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none; }
  .carousel .carousel {
    position: relative; }
  .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px; }
  .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden; }
  .carousel .thumbs {
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap; }
  .carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    width: 80px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px; }
    .carousel .thumb:focus {
      border: 3px solid #ccc;
      outline: none; }
    .carousel .thumb.selected, .carousel .thumb:hover {
      border: 3px solid #333; }
    .carousel .thumb img {
      vertical-align: top; }
  .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden; }
    .carousel.carousel-slider .control-arrow {
      top: 0;
      color: #fff;
      font-size: 26px;
      bottom: 0;
      margin-top: 0;
      padding: 5px; }
      .carousel.carousel-slider .control-arrow:hover {
        background: rgba(0, 0, 0, 0.2); }
  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in; }
    .carousel .slider-wrapper.axis-horizontal .slider {
      -ms-box-orient: horizontal;
      display: -moz-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-horizontal .slider .slide {
        flex-direction: column;
        flex-flow: column; }
    .carousel .slider-wrapper.axis-vertical {
      -ms-box-orient: horizontal;
      display: -moz-flex;
      display: flex; }
      .carousel .slider-wrapper.axis-vertical .slider {
        flex-direction: column; }
  .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%; }
    .carousel .slider.animated {
      transition: all 0.35s ease-in-out; }
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: #000; }
    .carousel .slide img {
      width: 100%;
      vertical-align: top;
      border: 0; }
    .carousel .slide iframe {
      display: inline-block;
      width: calc(100% - 80px);
      margin: 0 40px 40px;
      border: 0; }
    .carousel .slide .legend {
      transition: all 0.5s ease-in-out;
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin-left: -45%;
      width: 90%;
      border-radius: 10px;
      background: #000;
      color: #fff;
      padding: 10px;
      font-size: 12px;
      text-align: center;
      opacity: 0.25;
      transition: opacity 0.35s ease-in-out; }
  .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%; }
    @media (min-width: 960px) {
      .carousel .control-dots {
        bottom: 0; } }
    .carousel .control-dots .dot {
      transition: opacity 0.25s ease-in;
      opacity: 0.3;
      filter: alpha(opacity=30);
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      background: #fff;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      cursor: pointer;
      display: inline-block;
      margin: 0 8px; }
      .carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
        opacity: 1;
        filter: alpha(opacity=100); }
  .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff; }
  .carousel:hover .slide .legend {
    opacity: 1; }

/* Common, default styles for the notification box */

.s-alert-box,
.s-alert-box * {
    box-sizing: border-box;
}

.s-alert-box {
    position: fixed;
    background: rgba(42,45,50,0.85);
    padding: 22px;
    line-height: 1.4;
    z-index: 1000;
    pointer-events: none;
    color: rgba(250,251,255,0.95);
    font-size: 100%;
    font-family: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;
    max-width: 300px;
    transition: top .4s, bottom .4s;
}

.s-alert-box.s-alert-show {
    pointer-events: auto;
}

.s-alert-box a {
    color: inherit;
    opacity: 0.7;
    font-weight: 700;
}

.s-alert-box a:hover,
.s-alert-box a:focus {
    opacity: 1;
}

.s-alert-box p {
    margin: 0;
}

.s-alert-box.s-alert-show,
.s-alert-box.s-alert-visible {
    pointer-events: auto;
}

.s-alert-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4px;
    top: 4px;
    overflow: hidden;
    text-indent: 100%;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.s-alert-close:hover,
.s-alert-close:focus {
    outline: none;
}

.s-alert-close::before,
.s-alert-close::after {
    content: '';
    position: absolute;
    width: 3px;
    height: 60%;
    top: 50%;
    left: 50%;
    background: #fff;
}

.s-alert-close:hover::before,
.s-alert-close:hover::after {
    background: #fff;
}

.s-alert-close::before {
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
    transform: translate(-50%,-50%) rotate(45deg);
}

.s-alert-close::after {
    -webkit-transform: translate(-50%,-50%) rotate(-45deg);
    transform: translate(-50%,-50%) rotate(-45deg);
}

/* positions */

.s-alert-bottom-left {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 30px;
}
.s-alert-top-left {
    top: 30px;
    right: auto;
    bottom: auto;
    left: 30px;
}
.s-alert-top-right {
    top: 30px;
    right: 30px;
    bottom: auto;
    left: auto;
}
.s-alert-bottom-right { /*default*/
    top: auto;
    right: 30px;
    bottom: 30px;
    left: auto;
}
.s-alert-bottom {
    width: 100%;
    max-width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
}
.s-alert-top {
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
}

/* conditions */

.s-alert-info {
    background: #00A2D3;
    color: #fff;
}
.s-alert-success {
    background: #27AE60;
    color: #fff;
}
.s-alert-warning {
    background: #F1C40F;
    color: #fff;
}
.s-alert-error {
    background: #E74C3C;
    color: #fff;
}

[class^="s-alert-effect-"].s-alert-hide,
[class*=" s-alert-effect-"].s-alert-hide {
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

/* height measurement helper */
.s-alert-box-height {
    visibility: hidden;
    position: fixed;
}

/* stackslide (top, bottom - full width) */

.s-alert-effect-stackslide .s-alert-close {
    background: transparent;
}

.s-alert-effect-stackslide .s-alert-close::before,
.s-alert-effect-stackslide .s-alert-close::after {
    background: #fff;
}

.s-alert-effect-stackslide p {
    padding: 0 1.5em;
    display: inline-block;
}

.s-alert-effect-stackslide .s-alert-close::before,
.s-alert-effect-stackslide .s-alert-close::after {
    width: 2px;
    background: #fff;
}

.s-alert-effect-stackslide .s-alert-close:hover::before,
.s-alert-effect-stackslide .s-alert-close:hover::after {
    background: #fff;
}

.s-alert-top-left.s-alert-effect-stackslide.s-alert-show,
.s-alert-top-right.s-alert-effect-stackslide.s-alert-show,
.s-alert-top-left.s-alert-effect-stackslide.s-alert-hide,
.s-alert-top-right.s-alert-effect-stackslide.s-alert-hide,
.s-alert-top.s-alert-effect-stackslide.s-alert-show,
.s-alert-top.s-alert-effect-stackslide.s-alert-hide {
    -webkit-animation-name: animSlideTop;
    animation-name: animSlideTop;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

@-webkit-keyframes animSlideTop {
    0% { -webkit-transform: translate3d(0,-100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideTop {
    0% { -webkit-transform: translate3d(0,-100%,0); transform: translate3d(0,-100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}

.s-alert-bottom-left.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom-right.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom-left.s-alert-effect-stackslide.s-alert-hide,
.s-alert-bottom-right.s-alert-effect-stackslide.s-alert-hide,
.s-alert-bottom.s-alert-effect-stackslide.s-alert-show,
.s-alert-bottom.s-alert-effect-stackslide.s-alert-hide {
    -webkit-animation-name: animSlideBottom;
    animation-name: animSlideBottom;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

@-webkit-keyframes animSlideBottom {
    0% { -webkit-transform: translate3d(0,100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); }
}

@keyframes animSlideBottom {
    0% { -webkit-transform: translate3d(0,100%,0); transform: translate3d(0,100%,0); }
    100% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
}
